@import '../../scss/colors';
.surge-modal {
  &__button {
    border: 1px solid #dedede;
    color: #858585;
    border-radius: 8px;
    padding: 0.5rem;
    text-align: center;
    transition: 150ms linear;

    &.is-active {
      background-color: $red-1;
      border: 1px solid $red-2;
      color: $red-5;
    }
  }
}
