@import '../../scss/colors';

.meal-overflow-x-scroll flex px-2 mb-2 {
  overflow-x: hidden;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100vw;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;
  }
}

.display-options {
  background: $primary-white;
  width: 400px;
  border-radius: 4rem;
  gap: 2rem;
  margin: 2rem auto;
  box-shadow: 10px 10px 3px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 3px -5px rgba(0, 0, 0, 0.75);
}
.meal_filter_select {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60%;
  padding: 3px;
  font-size: 20px;
}
.meal_filter_select .react-select__control,
.meal_filter_select .react-select__option {
  padding: 15px !important;
}

.meal-settings {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .select-option {
    width: 100%;
  }
}
