@import 'config';
@import 'colors';
@import 'functions';
@import 'mixins';
@import 'variables';

//Presets
* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  margin: 0;
}
html,
#root,
body {
  height: 100%;
  font-family: Manrope, Arial, Helvetica, sans-serif;
  font-size: 14px;
}
html {
  overflow: auto;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
}
body {
  background-color: #0f0f0f;
  margin: 0;
  padding: 0;
}
// Thumb scrollbar
::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: #60677b;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
:disabled {
  opacity: 0.7;
}
// Thumb scrollbar end
.overflow-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  // &__lg {
  //   @include media-breakpoint-up(lg) {
  //     overflow-y: auto;
  //     overflow-x: hidden;
  //   }
  // }
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.not-allowed-button:disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.not-allowed-el {
  cursor: not-allowed !important;
}

button:hover,
button:active,
button:focus {
  outline: none;
  //border: none;
}
textarea:hover,
textarea:active,
textarea:focus {
  outline: none;
}
.text-area {
  border: 2px solid #0f0f0f;
  height: 60px;
}

// HOME CONTAINER
.overflow-x-scroll flex px-2 mb-2 {
  display: flex;
  overflow-x: scroll;
  margin: 12px 0;
  padding: 0px 6px;

  @media (max-width: 900px) {
    flex-direction: column;
    overflow-x: hidden;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

//alert styles
.app_alert {
  color: $primary-white;
  width: fit-content;
  border-radius: 5px;
  padding: 7px;
  position: relative;

  svg {
    color: $primary-white;
    margin: 0 10px 0 0;
    width: 14px;
    height: 14px;
    transform: scale(1.2);
  }
  .close_alert {
    margin-bottom: -3px;
  }
}
.app_alert_success {
  background-color: #4bb543;
}
.app_alert_error {
  background-color: #ff6a6a;
}
.app_alert_info {
  background-color: #f7b217;
}
// offline component
.offline-component {
  right: 10px;
  top: 120px;
  z-index: 99999;
}
.slide-left {
  @keyframes slideright {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50px);
    }
  }
}

.slide-down {
  animation: slideDown 0.5s forwards !important;
}
@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(20px);
  }
}
.slide-down-alt {
  animation: slideDown 0.1s forwards !important;
}
@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(3px);
  }
}
.zoom-in {
  animation: zoomin 0.5s forwards !important;
}
@keyframes zoomin {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}
.scale-right {
  animation: scaleRight 0.05s ease !important;
  transform-origin: 0% 50%;
}
@keyframes scaleRight {
  0% {
    transform: scaleX(0.85);
    color: transparent;
    opacity: 0;
  }
  100% {
    color: #fff;
    transform: scaleX(1);
    opacity: 1;
  }
}

.grid_third_two {
  display: grid;
  place-content: center;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media screen and(min-width:1300px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }
  @media screen and(max-width:1299px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }
  @media screen and(max-width:1028px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }

  @media screen and(max-width:440px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
  }
}
