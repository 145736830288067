.main {
  width: 60px;
  height: 28px;

  background: #34c659;
  border-radius: 40px;
  border: 2px solid #34c659;
  position: relative;
}

.innerCircle {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: white;
  transition: right 0.5s;
}

.off {
  background: rgba(120, 120, 128, 0.16);
  border: 2px solid rgba(120, 120, 128, 0.16);
}

.off > .innerCircle {
  right: 33px;
  transition: right 0.5s;
}
