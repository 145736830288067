@import '../../scss/colors';
.order-card {
  min-width: 450px;
  width: 33%;
  transition: all 0.1s ease-out;

  &-pinned {
    border: 5px solid greenyellow;
  }
  .order-card-header {
    min-width: 360px;
    width: 100%;

    .order-left {
      width: max-content;
      margin-right: 5px;
    }

    .order-btn {
      width: max-content;
    }
  }

  .reject-btn {
    border: 1px solid #e60a2b;
    background-color: white;
  }
}
.order_card {
  .order_card_title {
    border: 1px solid #e3e3e3;
  }

  .order_card_body {
    border: 1px solid #e3e3e3;
    .order_card_item {
      border-bottom: 1px solid #cecece;
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}
// Accordion styles

/* Style the buttons that are used to open and close the accordion panel */

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg) !important;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow-x: hidden;
  transition: max-height 0.3s ease;
}
.active-accordion-head {
  // border: 1px solid #e3e3e3;
  width: 100%;
}
// Accordion styles end
.receipt {
  .order_card_title,
  .order_card_body,
  .order_card_item {
    border: none !important;
  }
}
@media screen {
  .receipt {
    .order_card_title,
    .order_card_body,
    .order_card_item {
      border: none !important;
    }
    display: none;
    font-size: 9px;
    p,
    h6,
    label {
      font-size: 9px;
    }
  }
}
.image_size img {
  max-width: 110px;
}

.bg-gold {
  background: #d58300;
}

// DISPATCH

.dispatch_flex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.dispatch_item {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.item_food {
  background-color: red;
}

.item_shop {
  background-color: rgb(49, 80, 134);
}

.item_all {
  background-color: rgb(90, 49, 134);
}

.frontdesk_all {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card_text {
  padding: 0.0002rem 0.7rem;
  border-radius: 22px;
  font-size: 16px;
  color: white;
}

.receipt-text {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 0.4rem;
}

.delivery {
  width: 80%;
  text-align: right;
}
.horizontal-breaker {
  border: 1px solid #ededed;
  margin-bottom: 24px;
}
.food_count {
  border-radius: 50%;
  min-width: 25px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(77, 77, 77, 0.1);
  margin-right: 20px;
}
