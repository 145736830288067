#split_screen_modal {
  @media screen and (min-width: 768px) {
    .b-modal__content {
      width: 90vw;
      max-width: 830px;
      .screen_card {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
}
