* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import './css/tailwind.css';
@import '@cokitchen/cokitchen-auth/dist/main.css';
@import '@cokitchen/cokitchen-components/dist/main.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

@font-face {
  font-family: 'Euclid Circular A';
  src: url(./assets/fonts/euclid-circular-a/Euclid-Circular-A-Regular.ttf);
}

iframe {
  display: none !important;
}

.no-print {
  display: none;
}

@media print {
  .no-print {
    display: block !important;
  }
  html.print-sticker,
  body.print-sticker {
    width: 35mm;
    height: 20mm;
  }
  .pagebreak {
    page-break-before: always;
  }
}
