.main {
  display: flex;
  justify-content: space-between;
  color: #1a1a1a;
  font-weight: 500;
  border-bottom: 1px solid #ededed;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.main:last-of-type {
  border-bottom: none;
}

.title {
  font-size: 14px;
  width: 100%;
}

.titleDisabled {
  color: #a3a3a3;
}
.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

.detail {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.roundedButton {
  background-color: #f2f2f2;
  padding: 6px 10px;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  width: 100px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.roundedButton > svg {
  margin-right: 10px;
  min-width: 18px;
}
.roundedButton > input {
  width: calc(100% - 20px);
  background-color: transparent;
}
.roundedButton > input:focus,
.roundedButton > input:active {
  outline: none;
  box-shadow: none;
}

.lineButton {
  background: none;
  border: none;
  color: #0ea3fd;
  border-bottom: 1px solid #0ea3fd;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 1;
}

.soldOut {
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  background: #e60a2b;
  border-radius: 20px;
  display: inline-block;
  padding: 1px 6px;
  margin-left: 10px;
}
