.main {
  background-color: #551a8b;
  color: white;
  padding: 20px;
  margin: 0px 12px;
  height: 100%;
  min-width: 452px;
  width: 452px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

.body {
  background-color: white;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  color: #1a1a1a;
  overflow-y: auto;
  flex: 1 0 394px;
}

.body > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ededed;
}

.actions {
  display: flex;
  align-items: center;
}

.title {
  font-size: 16px;
  line-height: 25px;
}

.detail {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.roundedButton {
  background: #fee7ea;
  color: #e60a2b;
  padding: 6px 10px;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  margin-left: 10px;
}

.lineButton {
  background: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mainTitle {
  font-size: 500;
}
.mainTitle > div:nth-child(1) {
  font-size: 20px;
}
.mainTitle > div:nth-child(2) {
  font-size: 14px;
}

.mainAction {
  display: flex;
  align-items: center;
}

.mainAction > button {
  background: #ffffff;
  color: #551a8b;
  padding: 6px 10px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
}

.stickyTop {
  position: sticky;
  top: -20px;
  background: white;
  z-index: 10;
}
