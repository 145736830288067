/** @format */
// Colors
$primary-black: #000000;
$primary-purple: #b195bd;
$light-black: #0000001a;
$primary-white: #ffffff;
$primary-gold: #d58300;
$primary-green: #00aa34;
$primary-blue: #2699fb;
$light-blue: #2698fb1a;
$primary-red: #d50000;
$light-red: #d5000018;
$primary-grey: #b9b9b9;
$light-gray: rgba(129, 127, 127, 0.37);
$light-grey: rgba(129, 127, 127, 0.1);
$gray: #e2e2e2;

// reds
$red-1: #fee7ea;
$red-2: #fcb6c0;
$red-3: #fa8596;
$red-4: #f73b58;
// red-5 => primary red
$red-5: #e60a2b;
$red-6: #ab0720;
$red-7: #620412;

// greens
$green-1: #eaf8e7;
$green-2: #cbeec3;
$green-3: #a8e39c;
$green-4: #73d260;
// green-5 => primary green
$green-5: #46ae31;
$green-6: #388b27;
$green-7: #205016;

// yellows
$yellow-1: #fef7e1;
$yellow-2: #fdedbe;
$yellow-3: #fce08d;
$yellow-4: #fad051;
// yellow-5 => primary yellow
$yellow-5: #f9c320;
$yellow-6: #bd8f05;
$yellow-7: #634b03;

// blues
$blue-1: #e1f3ff;
$blue-2: #b3e2fe;
$blue-3: #81cffe;
$blue-4: #3fb6fd;
// blue-5 => primary blue
$blue-5: #0ea3fd;
$blue-6: #016fb1;
$blue-7: #014065;
