@import '../../scss/colors';
.header {
  background: $primary-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px 35px 20px 35px;
  // height: fit-content;
  width: 100%;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  img.logo {
    width: 250px;
    height: 50px;

    @media (max-width: 500px) {
      width: 90%;
      height: auto;
    }
  }
  .all-matches-btn {
    margin-left: 15px;
  }
}

.network-pill {
  border-radius: 1rem;
  display: flex;
  padding: 0.25rem 0.5rem;
  font-size: 14px;

  &.good {
    background-color: #dfffb5;
  }
  &.excellent {
    background-color: #30ff30;
  }
  &.average {
    background-color: #fffa4f;
  }
  &.bad {
    background-color: #ffc56e;
  }
  &.very-bad {
    background-color: #ffaba2;
  }
}

.orders {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 0.4rem;
}

.header-button {
  row-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.user {
  display: flex;
  align-items: center;
}

.left-header {
  display: flex;
  width: 40%;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.right-header {
  display: flex;
  width: 55%;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 768px) {
    align-items: flex-start;
    width: 100%;
  }

  // background: #000;
  // width: 100%;
}

.right-header-top {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.right-header-top input {
  width: 300px;

  @media (max-width: 600px) {
    width: 100%;
  }
}

// new styles

.arrow-up {
  position: absolute;
  right: 0.75rem;
  top: 0;
  z-index: 1;
}

.shadow-logout {
  filter: drop-shadow(0px 5px 30px rgba(102, 102, 102, 0.3));
}
