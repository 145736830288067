.packaging__screen_modal__infinite__scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2e303180;
    border-radius: 10px;
  }
}
