.main {
  border-radius: 20px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background-color: #242424;
}

.main + .main {
  margin-left: 1.625rem;
}

.main.active {
  background: #e60a2b;
}

.text {
  line-height: 25px;
  margin: 0px 6px;
  color: #595959;
  font-weight: 500;
  font-size: 16px;
}

.active > .text {
  color: white;
}

.active > .number {
  color: #e60a2b;
  background-color: white;
}

.number {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0px 2px;
  background-color: #2e2e2e;
  color: #595959;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
