.main {
  width: 100%;
  overflow-x: auto;
}

.main::-webkit-scrollbar {
  height: 7px;
}

.main > div {
  width: max-content;
}
