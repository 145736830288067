.packaging_screen__search_w_filter {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  outline: 0 solid #f0f0f0;
  transition: all 0.1s;

  &:has(input:focus) {
    border-color: #1a1a1a;
    outline-width: 4px;
  }
}
